import React, { useMemo, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import clsx from 'clsx';

import Icon from '../../styles/atoms/icons';

const CategoryFilter = ({ categories, activeFilter, onChangeFilter }) => {
  const [selected, setSelected] = useState(true);
  const cat = useMemo(
    () =>
      categories.map((i, idx) => (
        <div
          role="button"
          tabIndex={idx}
          className={clsx('category', activeFilter === i && 'active')}
          onClick={() => onChangeFilter(i)}
        >
          <p>{i}</p>
        </div>
      )),
    [activeFilter, categories, onChangeFilter]
  );

  return (
    <div className="filter">
      <Accordion key="integration-category-list" defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header
            eventKey="0"
            className="d-flex align-items-center"
            onClick={() => (selected ? setSelected(false) : setSelected(true))}
          >
            Categories
            <span className="icon">
              {selected ? (
                <Icon id="FiChevronDown" />
              ) : (
                <Icon id="FiChevronUp" />
              )}
            </span>
          </Accordion.Header>
          <Accordion.Body>
            <>{cat}</>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default CategoryFilter;
